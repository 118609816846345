<template>
    <div>
        <b-form-select style="width: auto; float:right;"
                       v-model="filtre"
                       :options="filtreler"></b-form-select>

        <div style="clear:both;"></div>
        <b-table stacked="sm"
                 :busy="isBusy"
                 :items="items"
                 :per-page="0"
                 :current-page="currentPage"
                 small
                 :fields="fields">
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Yükleniyor...</strong>
                </div>
            </template>
            <template v-slot:cell(index)="data">
                {{ (currentPage - 1) * boyut + data.index + 1  }}
            </template>
            <template v-slot:cell(tweetYazmaIzniVerdiMi)="data">
                <div class="h2">
                    <b-icon v-if="data.item.tweetYazmaIzniVerdiMi"  icon="check" variant="success"></b-icon>
                    <b-icon v-else icon="x" variant="danger"></b-icon>
                </div>
            </template>
            <template v-slot:cell(retweetIzniVerdiMi)="data">
                <div class="h2">
                    <b-icon v-if="data.item.retweetIzniVerdiMi"  icon="check" variant="success"></b-icon>
                    <b-icon v-else icon="x" variant="danger"></b-icon>
                </div>
            </template>
            <template v-slot:cell(likeIzniVerdiMi)="data">
                <div class="h2">
                    <b-icon v-if="data.item.likeIzniVerdiMi"  icon="check" variant="success"></b-icon>
                    <b-icon v-else icon="x" variant="danger"></b-icon>
                </div>
            </template>
            <template v-slot:cell(islem)="data">
                <b-button variant="danger"
                          @click="sil(data.item.id, data.index)">
                    <b-icon icon="x-circle"></b-icon>
                </b-button>
            </template>
        </b-table>
        <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="boyut"
                align="right"
        ></b-pagination>
    </div>
</template>

<script>
    import {GonulluleriGetir} from "@/api/hesap"
    import {GonulluSil} from "@/api/hesap"

    export default {
        name: "gonulluler",
        data() {
            return {
                isBusy: true,
                fields: [
                    {key: 'index', label: 'Sıra'},
                    {key: 'name', label: 'Ad'},
                    {key: 'tweetYazmaIzniVerdiMi', label: 'Tweet İzni'},
                    {key: 'retweetIzniVerdiMi', label: 'Retweet İzni'},
                    {key: 'likeIzniVerdiMi', label: 'Like İzni'},
                    {key: 'islem', label: 'İşlem'}
                ],
                items: [],
                filtre: null,
                filtreler: [
                    { value: null, text: 'Filtre Seçebilirsiniz' },
                    { value: 1, text: 'En Az Birine İzin Verenler' },
                    { value: 2, text: 'Hiç İzin Vermeyenler' },
                    { value: 0, text: 'Hepsi' },
                ],
                currentPage: 1,
                rows: 0,
                boyut: 20,
            }
        },
        watch: {
          filtre: async function() {
              this.currentPage = 1
              await this.gonulluleriGetir()
          },
          currentPage: async function() {
              await this.gonulluleriGetir()
          }
        },
        methods: {
            async sil(id, index) {
                await GonulluSil(id)
                this.items.splice(index, 1)
            },
            async gonulluleriGetir() {
                this.isBusy = true
                const { data, totalRows } = await GonulluleriGetir(this.currentPage, this.boyut, this.filtre)
                console.log(data)
                this.rows = totalRows
                this.items = data
                this.isBusy = false
            }
        },
        async created() {
            this.gonulluleriGetir()
        }
    }
</script>

<style scoped>

</style>
