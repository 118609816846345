import http from '@/request'

export const RetweetIziniSayisi = () => {
    return http.request({
        url: 'hesap/RetweetIziniSayisi',
    })
}

export const LikeIziniSayisi = () => {
    return http.request({
        url: 'hesap/LikeIziniVerenSayisi'
    })
}

export const TweetAtmaIziniVerenSayisi = () => {
    return http.request({
        url: 'hesap/TweetAtmaIziniVerenSayisi'
    })
}

export const GonulluleriGetir = (sayfa = 1, boyut = 10, filtre = null) => {
    const params = {
        sayfa,
        boyut
    }
    if (filtre != null) {
        params['filtre'] = filtre
    }

    return http.request({
        url: 'hesap/GonulluleriGetir',
        params: params
    })
}

export const GonulluSil = (id) => {
    return http.request({
        url: 'hesap/GonulluSil',
        method: 'delete',
        params: { id }
    })
}
